@page {
  margin-left: 0.39370078740157in;
  margin-right: 0in;
  margin-top: 0.39370078740157in;
  margin-bottom: 0.78740157480315in;
}
@media print {
  body {
    margin-left: 0.39370078740157in;
    margin-right: 0in;
    margin-top: 0.39370078740157in;
    margin-bottom: 0.78740157480315in;
  }
}
.print {
  display: none;
}

html {
  font-family: Calibri, Arial, Helvetica, sans-serif;
  font-size: 11pt;
  background-color: white;
}
a.comment-indicator:hover + div.comment {
  background: #ffd;
  position: absolute;
  display: block;
  border: 1px solid black;
  padding: 0.5em;
}
a.comment-indicator {
  background: red;
  display: inline-block;
  border: 1px solid black;
  width: 0.5em;
  height: 0.5em;
}
div.comment {
  display: none;
}
table {
  border-collapse: collapse;
  page-break-after: always;
}
.gridlines td {
  border: 1px dotted black;
}
.gridlines th {
  border: 1px dotted black;
}
.b {
  text-align: center;
}
.e {
  text-align: center;
}
.f {
  text-align: right;
}
.inlineStr {
  text-align: left;
}
.n {
  text-align: right;
}
.s {
  text-align: left;
}
td.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial Cyr";
  font-size: 10pt;
  background-color: white;
}
th.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial Cyr";
  font-size: 10pt;
  background-color: white;
}
td.style1 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style1 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style2 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 18pt;
  background-color: white;
}
th.style2 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 18pt;
  background-color: white;
}
td.style3 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style3 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style4 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 18pt;
  background-color: white;
}
th.style4 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 18pt;
  background-color: white;
}
td.style5 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #ff0000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style5 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #ff0000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style6 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style6 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style7 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style7 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style8 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style8 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style9 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style9 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style10 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style10 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style11 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style11 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style12 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style12 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style13 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style13 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style14 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style14 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style15 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style15 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style16 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style16 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style17 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style17 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style18 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style18 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style19 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style19 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style20 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style20 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style21 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style21 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style22 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style22 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style23 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style23 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style24 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style24 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style25 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
th.style25 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
td.style26 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
th.style26 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
td.style27 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style27 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style28 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style28 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style29 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: white;
}
th.style29 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: white;
}
td.style30 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style30 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style31 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style31 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style32 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style32 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style33 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style33 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style34 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style34 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style35 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style35 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style36 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style36 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style37 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style37 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style38 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style38 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style39 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style39 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style40 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style40 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style41 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style41 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style42 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style42 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style43 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style43 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style44 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style44 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style45 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style45 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style46 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style46 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style47 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style47 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style48 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style48 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style49 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style49 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style50 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style50 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style51 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style51 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style52 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style52 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style53 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style53 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style54 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style54 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style55 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style55 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style56 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style56 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style57 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style57 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style58 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style58 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style59 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style59 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style60 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style60 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style61 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style61 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style62 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style62 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style63 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style63 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style64 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style64 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style65 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style65 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style66 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style66 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style67 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style67 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style68 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style68 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style69 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style69 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style70 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style70 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style71 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style71 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style72 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style72 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style73 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style73 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style74 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style74 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style75 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style75 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style76 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style76 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style77 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style77 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style78 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style78 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style79 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style79 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style80 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style80 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style81 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style81 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style82 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style82 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style83 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style83 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style84 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style84 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style85 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style85 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style86 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style86 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style87 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style87 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style88 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style88 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style89 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style89 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style90 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style90 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style91 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style91 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style92 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style92 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style93 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style93 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style94 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style94 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style95 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style95 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style96 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
th.style96 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
td.style97 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style97 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style98 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style98 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style99 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style99 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style100 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: white;
}
th.style100 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: white;
}
td.style101 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: white;
}
th.style101 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: white;
}
td.style102 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style102 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style103 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: white;
}
th.style103 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: white;
}
td.style104 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style104 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style105 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style105 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style106 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style106 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style107 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style107 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style108 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style108 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style109 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style109 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style110 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style110 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style111 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style111 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style112 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style112 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style113 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style113 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style114 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style114 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style115 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style115 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style116 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style116 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style117 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style117 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style118 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style118 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style119 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style119 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style120 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style120 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style121 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style121 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style122 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style122 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style123 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style123 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style124 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
th.style124 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
td.style125 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style125 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style126 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style126 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style127 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style127 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style128 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style128 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style129 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
th.style129 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 7pt;
  background-color: white;
}
td.style130 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style130 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style131 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style131 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style132 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style132 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style133 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style133 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style134 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style134 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style135 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style135 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style136 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style136 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style137 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style137 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style138 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style138 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style139 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style139 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style140 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style140 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style141 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 12pt;
  background-color: white;
}
th.style141 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 12pt;
  background-color: white;
}
td.style142 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 12pt;
  background-color: white;
}
th.style142 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 12pt;
  background-color: white;
}
td.style143 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 12pt;
  background-color: white;
}
th.style143 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 12pt;
  background-color: white;
}
td.style144 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style144 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style145 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style145 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style146 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style146 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style147 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style147 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style148 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style148 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style149 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style149 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style150 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style150 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style151 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style151 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style152 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style152 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style153 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style153 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style154 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style154 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style155 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
th.style155 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 8pt;
  background-color: white;
}
td.style156 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style156 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style157 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style157 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style158 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style158 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style159 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style159 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style160 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style160 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style161 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style161 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style162 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 13pt;
  background-color: white;
}
th.style162 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 13pt;
  background-color: white;
}
td.style163 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 13pt;
  background-color: white;
}
th.style163 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 13pt;
  background-color: white;
}
td.style164 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 13pt;
  background-color: white;
}
th.style164 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 13pt;
  background-color: white;
}
td.style165 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style165 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style166 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style166 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style167 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style167 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style168 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style168 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style169 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style169 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style170 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style170 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style171 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: #ffffff;
}
th.style171 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: #ffffff;
}
td.style172 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: #ffffff;
}
th.style172 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: #ffffff;
}
td.style173 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: #ffffff;
}
th.style173 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 9pt;
  background-color: #ffffff;
}
td.style174 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style174 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style175 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style175 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style176 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style176 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style177 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
th.style177 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: white;
}
td.style178 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style178 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style179 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style179 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Cambria";
  font-size: 10pt;
  background-color: #ffffff;
}
table.sheet0 col.col0 {
  width: 85.31111066pt;
}
table.sheet0 col.col1 {
  width: 45.31111066pt;
}
table.sheet0 col.col2 {
  width: 45.31111066pt;
}
table.sheet0 col.col3 {
  width: 45.6666662pt;
}
table.sheet0 col.col4 {
  width: 50.41111059pt;
}
table.sheet0 col.col5 {
  width: 49.73333282pt;
}
table.sheet0 col.col6 {
  width: 53.12222167pt;
}
table.sheet0 col.col7 {
  width: 55.15555498pt;
}
table.sheet0 col.col8 {
  width: 53.79999944pt;
}
table.sheet0 col.col9 {
  width: 55.15555498pt;
}
table.sheet0 col.col10 {
  width: 59.2222216pt;
}
table.sheet0 col.col11 {
  width: 56.51111052pt;
}
table.sheet0 col.col12 {
  width: 45.31111066pt;
}
table.sheet0 col.col13 {
  width: 42pt;
}
table.sheet0 col.col14 {
  width: 42pt;
}
table.sheet0 col.col15 {
  width: 42pt;
}
table.sheet0 col.col16 {
  width: 42pt;
}
table.sheet0 col.col17 {
  width: 42pt;
}
table.sheet0 .column0 {
  visibility: collapse;
  display: none;
}
table.sheet0 tr {
  height: 13.636363636364pt;
}
table.sheet0 tr.row0 {
  height: 13.5pt;
}
table.sheet0 tr.row3 {
  height: 13.8pt;
}
table.sheet0 tr.row4 {
  height: 12.75pt;
}
table.sheet0 tr.row5 {
  height: 13.8pt;
}
table.sheet0 tr.row9 {
  height: 13.8pt;
}
table.sheet0 tr.row10 {
  height: 13.8pt;
}
table.sheet0 tr.row11 {
  height: 13.8pt;
}
table.sheet0 tr.row12 {
  height: 13.8pt;
}
table.sheet0 tr.row13 {
  height: 13.8pt;
}
table.sheet0 tr.row14 {
  height: 13.8pt;
}
table.sheet0 tr.row15 {
  height: 22.5pt;
}
table.sheet0 tr.row21 {
  height: 13.8pt;
}
table.sheet0 tr.row22 {
  height: 13.8pt;
}
table.sheet0 tr.row23 {
  height: 13.8pt;
}
table.sheet0 tr.row24 {
  height: 13.8pt;
}
table.sheet0 tr.row25 {
  height: 13.8pt;
}
table.sheet0 tr.row26 {
  height: 19.5pt;
}
table.sheet0 tr.row27 {
  height: 15pt;
}
table.sheet0 tr.row28 {
  height: 13.8pt;
}
table.sheet0 tr.row30 {
  height: 13.8pt;
}
table.sheet0 tr.row31 {
  height: 13.8pt;
}
table.sheet0 tr.row34 {
  height: 13.8pt;
}
table.sheet0 tr.row35 {
  height: 24pt;
}
table.sheet0 tr.row36 {
  height: 13.8pt;
}
table.sheet0 tr.row37 {
  height: 13.8pt;
}
table.sheet0 tr.row38 {
  height: 14.25pt;
}
table.sheet0 tr.row39 {
  height: 14.25pt;
}
table.sheet0 tr.row40 {
  height: 14.25pt;
}
table.sheet0 tr.row41 {
  height: 14.25pt;
}
table.sheet0 tr.row42 {
  height: 14.25pt;
}
table.sheet0 tr.row43 {
  height: 14.25pt;
}
table.sheet0 tr.row44 {
  height: 14.25pt;
}
table.sheet0 tr.row45 {
  height: 14.25pt;
}
table.sheet0 tr.row46 {
  height: 14.25pt;
}
table.sheet0 tr.row47 {
  height: 14.25pt;
}
table.sheet0 tr.row48 {
  height: 21.75pt;
}
table.sheet0 tr.row49 {
  height: 14.25pt;
}
table.sheet0 tr.row50 {
  height: 9pt;
}
table.sheet0 tr.row53 {
  height: 14.25pt;
}
table.sheet0 tr.row54 {
  height: 9.75pt;
}
